import * as React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import Seo from '../components/seo'
import ContactForm from '../components/contactForm'

export default function OurWork({ data }) {
  const clients = data.webiny.listClients.data;

  return (
    <Layout pageTitle="Our Work">
    <Seo 
      title="Our Work | Web Design, eCommerce, UI/UX, Branding, and App Development"
      description="Our Work at BRADIENT, this includes web design, ecommerce web design, branding, digital marketing, websites, UI/UX design, and mobile app design, with accompanying case studies." 
    />
      <div className=''>
        <div className='grid grid-cols-1 lg:grid-cols-6 gap-5'>
          <div className='lg:col-span-4'>
          {clients.map(client => {
            return (client.visibility) ?
              <div className='grid grid-cols-1 lg:grid-cols-12 gap-5 py-10 first-of-type:!pt-0 last-of-type:!border-b-0 border-b border-slate-200'>

                <div className='lg:col-span-6'>
                  <div className=''>
                      <Link href={client.clientWebsiteUrl} target="_blank">
                        {client.clientAsset1.includes('.mp4') ?
                          <video playsInline muted autoPlay loop className='rounded-lg border border-slate-100'>
                            <source src={client.clientAsset1} type='video/mp4' />
                          </video>
                        :
                          <img className="border border-slate-100" src={client.clientAsset1} alt={client.clientShortDescription} title={`${client.clientName} Website Preview`} />
                        }
                      </Link>
                  </div>
                </div>

                <div className='lg:col-span-6'>
                  <div className=''>
                      <h2 className="text-xl text-black font-semibold md:mb-3">{client.clientName}</h2>
                      <p className='text-xs'>
                        {client.clientShortDescription}
                        <br />
                        <br />
                        <strong>Website:</strong> <Link href={client.clientWebsiteUrl} target="_blank" className="!lowercase">{client.clientWebsiteUrl}</Link>
                      </p>
                  </div>
                </div>

              </div>
              :
              null
          })}
          </div>

          <div className='lg:col-span-2'>
            <div className="float-right sticky top-24 inline-block">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    webiny {
      listClients(sort: clientProjectDateCompleted_DESC, limit: 20) {
        data {
          id
          createdOn
          clientLogo
          clientWebsiteUrl
          clientShortDescription
          clientName
          clientAsset1
          visibility
        }
      }
    }
  }
`